import React, { useEffect } from 'react'
import { isAndroid, isIOS, isMobile  } from "react-device-detect";
import Loader from '../../ui-components/Loader/Loader';

export default function DetectDeviceTypeRedirect(props) {

  useEffect(() => {
    if (isMobile) {
      if (isIOS) {
        // Redirect to App Store
        window.location.href = "https://apps.apple.com/in/app/nativenest/id6504481125";
      } else {
        // Redirect to Play Store
        window.location.href = "https://play.google.com/store/apps/details?id=com.nativeroot.nativenest";
        // window.location.href = "https://cdn.nativenest.in/pro/app/nativenest.apk";
      }
    } else {
      // Redirect to the website's homepage
      window.location.href = "/";
    }
  }, []);

  return (
    <Loader loading={true} />
  )
}